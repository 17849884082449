function _extends() {
    return (_extends = Object.assign || function(target) {
        for(var i = 1; i < arguments.length; i++){
            var source = arguments[i];
            for(var key in source)Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
        }
        return target;
    }).apply(this, arguments);
}
import { InMemoryCache } from "@apollo/client/cache/inmemory/inMemoryCache";
import { ApolloClient } from "@apollo/client/core/ApolloClient";
import { BatchHttpLink } from "@apollo/client/link/batch-http/batchHttpLink";
import { ApolloLink } from "@apollo/client/link/core/ApolloLink";
import { split } from "@apollo/client/link/core/split";
import { RetryLink } from "@apollo/client/link/retry";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { CheckConnectionWS } from "./CheckConnetionWS";
let id = 0, depth = 0;
let DebugApolloClient = class DebugApolloClient extends ApolloClient {
    stop() {
        super.stop(), depth = Math.min(1, depth);
    }
};
export function createApolloClient(token, role) {
    if (void 0 === role && (role = "user"), "undefined" == typeof window) return new ApolloClient({
        cache: new InMemoryCache()
    });
    let url = new URL(HASURA_ENDPOINT), params = token ? {
        headers: {
            Authorization: "Bearer " + token,
            "Expected-Role": role
        }
    } : {};
    url.username = "";
    // 创建一个自定义的 Apollo Link，用于记录请求
    let logLink = new ApolloLink((operation, forward)=>{
        // 打印出请求信息
        let color = 360 * Math.random(), currentId = ++id;
        try {
            console.log("%s%c[%d] Starting request for %s", " ".repeat(depth++), "background-color: black; color: hsl(" + color + ", 100%, 70%)", currentId, operation.operationName);
        } catch (e) {}
        return forward(operation).map((result)=>{
            // 请求完成后打印出结果
            try {
                console.log("%s%c[%d] Ending request for %s", " ".repeat(Math.max(0, --depth)), "background-color: hsl(" + color + ", 100%, 20%); color: white", currentId, operation.operationName);
            } catch (e) {}
            return result;
        });
    }), httpLink = new BatchHttpLink(_extends({
        uri: url.toString()
    }, params)), splitLink = split((param)=>{
        let { query } = param, definition = getMainDefinition(query);
        return "OperationDefinition" === definition.kind && "subscription" === definition.operation;
    }, new GraphQLWsLink(createClient({
        url: url.toString().replace(/^http/, "ws"),
        connectionParams: params,
        retryAttempts: 100,
        shouldRetry: ()=>!0,
        webSocketImpl: CheckConnectionWS
    })), httpLink), retryLink = new RetryLink();
    return new DebugApolloClient({
        link: ApolloLink.from([
            retryLink,
            logLink,
            splitLink
        ]),
        cache: new InMemoryCache({
            typePolicies: {
                cabin_character: {
                    keyFields: [
                        "cabin_id",
                        "character_id"
                    ]
                },
                sector0xquery_root: {
                    queryType: !0
                }
            }
        })
    });
}
